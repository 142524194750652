import { GetStaticPaths, GetStaticProps } from 'next';

import { nextRedirects } from 'constants/nextConfigRedirectsArray';
import { LazyContainers } from 'containers/LazyContainers';
import { NotFoundPageProps } from 'containers/NotFoundPage/NotFoundPage';

import MaintenanceBanner from 'components/MaintenanceBanner';

import { getPage } from '../api/wagtail';
import { ComponentName } from '../containers/LazyContainers';

interface Props {
    componentName: ComponentName;
    componentProps: object;
    statusCode: number;
    errorMessage?: string;
}

const handleForbiddenResponse = (componentName: ComponentName) => {
    const NotFoundComponent = LazyContainers[
        'NotFoundPage'
    ] as React.FC<NotFoundPageProps>;

    return <NotFoundComponent componentName={componentName} />;
};

const excludedFromErrorHandling: ComponentName[] = ['ProfileVacancyPage'];

const CatchAllPage = ({ componentName, componentProps, statusCode }: Props) => {
    const Component = LazyContainers[componentName] as React.FC;
    const showMaintenance = statusCode >= 500 && statusCode !== 503;
    const isForbidden = statusCode === 403;

    if (showMaintenance) return <MaintenanceBanner maintenance_mode={true} />;

    /*
        in some cases componentName is present,
        componentProps are empty and statusCode is 403.
        so LazyContainers mapping is fired and page is rendered with no data.

        This will be checked and handled in ProfileVacancyPage component.
     */
    if (
        isForbidden &&
        !excludedFromErrorHandling.some((item) => item === componentName)
    )
        return handleForbiddenResponse(componentName);

    return <Component {...componentProps} />;
};

// For SSR

// export const getServerSideProps: GetServerSideProps = async ({
//     req,
//     params,
// }) => {
//     const isBuild = process.env.NEXT_PHASE == 'phase-production-build';
//     if (isBuild) return { notFound: true, revalidate: true };

//     const path = getUrlString(params?.path);
//     const host = req.headers.host;
//     const apiUrl = getWagtailApiUrl({ host });

//     const { json: pageData, statusCode } = await getPage(path, apiUrl);

//     const redirectProps = nextRedirects.find(({ source }) => path === source);
//     if (redirectProps) return { redirect: redirectProps };

//     return {
//         props: { ...pageData, statusCode },
//         notFound: statusCode === 404,
//     };
// };

// For SSG

export const getStaticProps: GetStaticProps = async ({ params }) => {
    const isBuild = process.env.NEXT_PHASE == 'phase-production-build';

    if (isBuild) return { notFound: true, revalidate: true };

    let path = '/';
    const paramsPath = params?.path || '';
    const isArrayPath = Array.isArray(paramsPath);
    path = path.concat(isArrayPath ? paramsPath.join('/') : paramsPath);

    const { json: pageData, statusCode } = await getPage(path);

    const redirectProps = nextRedirects.find(({ source }) => path === source);
    if (redirectProps) return { redirect: redirectProps };

    return {
        props: { ...pageData, statusCode },
        revalidate: 30,
        notFound: statusCode === 404,
    };
};

export const getStaticPaths: GetStaticPaths = async () => {
    // let htmlUrls;

    // const { json: data } = await getAllPages();
    // htmlUrls = data.items.map((x) => x.relativeUrl);

    // htmlUrls = htmlUrls.filter((x) => x);
    // htmlUrls = htmlUrls.map((x) => x.split('/'));
    // htmlUrls = htmlUrls.map((x) => x.filter((y) => y));
    // htmlUrls = htmlUrls.filter((x) => x.length);

    // const paths = htmlUrls.map((x) => ({ params: { path: x } }));

    return {
        paths: [],
        fallback: 'blocking',
    };
};

export default CatchAllPage;

// export const getStaticPaths: GetStaticPaths = async () => {
//     const { json: data } = await getAllPages();

//     const urls = data.items.map((obj) => obj.relativeUrl);

//     const nestedUrls = urls.map((url) => {
//         if (url === '/') {
//             return [url];
//         }

//         const filteredUrl = url.split('/').filter((url) => url);

//         return filteredUrl;
//     });

//     const paths = nestedUrls.map((x) => ({ params: { path: x } }));

//     return {
//         paths: paths,
//         fallback: 'blocking',
//     };
// };

// // For SSR
// export const getServerSideProps: GetServerSideProps = ({ req, params, res }) => {
//     let path = params?.path || [];
//     path = path.join('/');

//     const { host } = req.headers;
//     let queryParams = new URL(req.url, `https://${host}`).search;
//     if (queryParams.indexOf('?') === 0) {
//         queryParams = queryParams.substr(1);
//     }
//     queryParams = querystring.parse(queryParams);

//     // Try to serve page
//     try {
//         const {
//             json: {
//                 componentName,
//                 componentProps,
//                 redirect,
//                 customResponse,
//             },
//             headers,
//         } = await getPage(
//             path,
//             queryParams, {
//                 headers: {
//                     cookie: req.headers.cookie,
//                     host,
//                 },
//             }
//         );

//         // Forward any cookie we encounter
//         const cookies = headers.get('set-cookie');
//         if (cookies) {
//             res.setHeader('Set-Cookie', cookies);
//         }

//         if (customResponse) {
//             const { body, body64, contentType } = customResponse;
//             res.setHeader('Content-Type', contentType);
//             res.statusCode = 200;
//             res.write(
//                 body64 ? Buffer.from(body64, 'base64') : body
//             );
//             res.end();

//             return { props: {} };
//         }

//         if (redirect) {
//             const { destination, isPermanent } = redirect;
//             return {
//                 redirect: {
//                     destination: destination,
//                     permanent: isPermanent,
//                 }
//             }
//         }

//         return { props: { componentName, componentProps } };
//     } catch (err) {
//         if (!(err instanceof WagtailApiResponseError)) {
//             throw err;
//         }

//         // When in development, show django error page on error
//         if (!isProd && err.response.status >= 500) {
//             const html = await err.response.text();
//             return {
//                 props: {
//                     componentName: 'PureHtmlPage',
//                     componentProps: { html },
//                 },
//             };
//         }

//         if (err.response.status >= 500) {
//             throw err;
//         }
//     }

//     // Try to serve redirect
//     try {
//         const { json: redirect } = await getRedirect(path, queryParams, {
//             headers: {
//                 cookie: req.headers.cookie,
//                 host,
//             },
//         });
//         const { destination, isPermanent } = redirect;
//         return {
//             redirect: {
//                 destination: destination,
//                 permanent: isPermanent,
//             }
//         }
//     } catch (err) {
//         if (!(err instanceof WagtailApiResponseError)) {
//             throw err;
//         }

//         if (err.response.status >= 500) {
//             throw err;
//         }
//     }

//     // Serve 404 page
//     return { notFound: true };
// }
